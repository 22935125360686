import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";
import { AccidentForm } from "types/types";

import { ReactComponent as ArrowBack } from "assets/icons/arrow-back.svg";
import ErrorAlert from "components/molecules/ErrorAlert";
import Placeholders from "pages/check-lists/components/Placeholders";
import AccidentsList from "components/molecules/AccidentsList";

import { ContentContainer, OptionsContainer } from "styles/generalStyles";

const Header = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
  position: relative;
  font-size: 2.4rem;
  font-family: AuraAspect;

  color: ${({ theme }) => theme.primary};

  .arrow {
    width: 3rem;
    height: 3rem;
    position: absolute;
    bottom: -0.3rem;
    left: 0;

    cursor: pointer;
  }
`;

function AccidentFormsList() {
  const {
    state: { accidentForms },
    setAccidentStartTimeAction,
    setSelectedAccidentFormAction,
    accidentFormsError: isError,
    accidentFormsLoading: isLoading,
    fetchAccidentForms,
  } = useContext(QuestionnairesContext);
  const { t } = useTranslation("alarms");

  const navigate = useNavigate();

  let content = null;

  const onAlarmClick = (id: string) => () => {
    const chosenForm = accidentForms?.find((form) => form.id === id) as AccidentForm;

    setSelectedAccidentFormAction(chosenForm);
    setAccidentStartTimeAction(new Date());
    navigate("/accident-form/1");
  };

  const onArrowClick = () => {
    const pathArray: string[] = JSON.parse(
      localStorage.getItem("alarmsReturnPath") as string,
    );

    const path = pathArray.pop() as string;

    if (pathArray.length) {
      localStorage.setItem("alarmsReturnPath", JSON.stringify(pathArray));
    } else {
      localStorage.removeItem("alarmsReturnPath");
    }

    navigate(path, { replace: true });
  };

  if (accidentForms) {
    content = <AccidentsList accidentForms={accidentForms} onClick={onAlarmClick} />;
  }

  if (isLoading) {
    content = <Placeholders />;
  }

  if (isError) {
    content = (
      <ErrorAlert
        onClick={fetchAccidentForms}
        message={t("error.unable-to-load-alarms")}
      />
    );
  }

  return (
    <ContentContainer>
      <Header>
        <ArrowBack className='arrow' onClick={onArrowClick} />
        <span>{t("header")}</span>
      </Header>
      <OptionsContainer>{content}</OptionsContainer>
    </ContentContainer>
  );
}

export default AccidentFormsList;

import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

import packageInfo from "../../../package.json";

import { device, getCurrentYear } from "utils/utils";
import UserContext from "contexts/user-context/UserContext";

import { ContentContainer } from "styles/generalStyles";

import logo from "assets/logos/SnapCheck-11.png";
import { ReactComponent as UserIcon } from "assets/icons/profile-user.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

import ErrorAlert from "components/molecules/ErrorAlert";
import Flags from "components/molecules/flags/Flags";
import AvatarImage from "components/molecules/avatar-image/AvatarImage";

const InfoCard = styled.div`
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.textColor1};
  padding-right: 2.5rem;
  padding-bottom: 1rem;
  padding-left: 2.5rem;
  border-radius: 0.6rem;
  margin-top: 1.8rem;
  ${({ theme }) => theme.cardShadow};
`;

const LogoContainer = styled.div`
  width: 17rem;
  height: 5rem;
`;

const LogoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-left: -1.5rem;
`;

const Row = styled.div<{
  alignRight?: boolean;
  noBorder?: boolean;
  noPadding?: boolean;
  department?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ alignRight }) => (alignRight ? "flex-end" : "space-between")};
  color: inherit;
  padding: ${({ noPadding }) => (!noPadding ? "1rem 0" : "0")};
  position: relative;
  border-bottom: ${({ theme }) => `1px solid ${theme.jetGrey}`};

  ${({ noBorder }) =>
    noBorder &&
    css`
      border-bottom: none;
    `}

  .close-icon {
    width: 3rem;
    height: 3rem;
    fill: ${({ theme }) => theme.white};

    position: absolute;
    top: 1rem;
    right: -0.5rem;

    cursor: pointer;
  }

  .copyright-container {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.primary_100};
  }

  ${({ department }) =>
    department &&
    css`
      padding-bottom: 0;
    `}
`;

const Label = styled.span`
  margin-right: 0.5rem;
  font-size: 1.4rem;
  user-select: none;

  @media ${device.iPad} {
    font-size: 1.6rem;
  }
`;

const Value = styled.span<{ smallFont?: boolean; email?: boolean }>`
  font-size: ${({ smallFont }) => (smallFont ? "1.4rem" : "1.6rem")};
  max-width: 85%;
  ${({ theme }) => theme.ellipsis}
  ${({ email }) =>
    email &&
    css`
      max-width: 100%;
    `}


  .year {
    font-size: 1.4rem;
    padding-top: 0.1rem;

    @media ${device.iPad} {
      font-size: 1.6rem;
      padding-top: 0;
    }
  }

  @media ${device.iPad} {
    font-size: ${({ smallFont }) => (smallFont ? "1.8rem" : "2rem")};
  }
`;

const CompanyDept = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 1.5rem;
`;

const CompanyName = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.4rem;
  font-style: italic;
  max-width: 100%;
  padding-right: 1rem;
  ${({ theme }) => theme.ellipsis}

  @media ${device.iPad} {
    font-size: 1.8rem;
  }
`;

const User = styled.div`
  position: relative;

  .user-icon {
    fill: ${({ theme }) => theme.primary_200};
    width: 3rem;
    height: 3rem;
  }
`;

const AvatarContainer = styled.div`
  position: absolute;
  top: 1rem;
  left: 0;
`;

const Loader = styled.div<{ width: number }>`
  height: 1.6rem;
  width: ${({ width }) => `${width}rem`};
  position: relative;
  background-color: ${({ theme }) => theme.snapCheckBlueLight};
  overflow: hidden;
  border-radius: 0.4rem;
  opacity: 0.6;
`;

const Shimmer = styled.div`
  ${({ theme }) => theme.shimmerEffect}
`;

const Placeholder = ({ width }: { width: number }) => (
  <Loader width={width}>
    <Shimmer />
  </Loader>
);

function Profile() {
  const [clickCount, setClickCount] = useState(0);
  const navigate = useNavigate();
  const { user, isError, isLoading } = useContext(UserContext);
  const { t } = useTranslation("common");

  let userAvatar = null;
  let companyDept = null;

  // --------------- Content ---------------

  if (user && user.avatar) {
    const { avatar } = user;
    const avatarId = typeof avatar === "string" ? avatar : avatar.id;

    userAvatar = <AvatarImage avatarId={avatarId} large />;
  } else {
    userAvatar = <UserIcon className='user-icon' />;
  }

  if (user) {
    if ("Department" in user) {
      const { Department } = user;

      if (Department) {
        const companyName = Department.Company ? Department.Company.CompanyName : "";
        const deptName = Department.DepartmentName ?? "";

        companyDept = (
          <CompanyDept>
            <CompanyName>{companyName}</CompanyName>
            <Value smallFont>{deptName}</Value>
          </CompanyDept>
        );
      }
    }

    if ("Departments" in user) {
      const { Departments } = user;

      if (Departments && Departments.length) {
        let companyName = "";

        if (Departments[0].Departments_id) {
          companyName = Departments[0].Departments_id.Company
            ? Departments[0].Departments_id.Company.CompanyName
            : "";
        }

        companyDept = (
          <CompanyDept>
            <CompanyName>{companyName}</CompanyName>
            {Departments.map(
              ({ Departments_id }, index) =>
                Departments_id && (
                  <Value key={index} smallFont>
                    {Departments_id.DepartmentName}
                  </Value>
                ),
            )}
          </CompanyDept>
        );
      }
    }
  }

  // --------------- On click handlers ---------------

  const onCloseIconClick = () => {
    const pathArray: string[] = JSON.parse(
      localStorage.getItem("profileReturnPath") as string,
    );

    const path = pathArray.pop() as string;

    if (pathArray.length) {
      localStorage.setItem("profileReturnPath", JSON.stringify(pathArray));
    } else {
      localStorage.removeItem("profileReturnPath");
    }

    navigate(path, { replace: true });
  };

  const onVersionClick = () => {
    setClickCount((prev) => prev + 1);
  };

  // --------------- Effect handler ---------------

  useEffect(() => {
    if (clickCount === 5) {
      navigate("/device-info");
    }
  }, [clickCount, navigate]);

  return (
    <ContentContainer>
      <InfoCard>
        <Row>
          <LogoContainer>
            <LogoImage src={logo} alt='' />
          </LogoContainer>

          <CloseIcon className='close-icon' onClick={onCloseIconClick} />
        </Row>

        {!isError ? (
          <User>
            <AvatarContainer>{userAvatar}</AvatarContainer>
            <Row noBorder alignRight>
              {!isLoading ? (
                <Value>
                  {user?.first_name}&nbsp;{user?.last_name}
                </Value>
              ) : (
                <Placeholder width={16} />
              )}
            </Row>

            <Row alignRight noBorder noPadding>
              {!isLoading ? (
                <Value smallFont>{user?.role.name}</Value>
              ) : (
                <Placeholder width={11} />
              )}
            </Row>

            <Row alignRight>
              {!isLoading ? (
                <Value email smallFont>
                  {user?.email}
                </Value>
              ) : (
                <Placeholder width={18} />
              )}
            </Row>

            <Row alignRight>
              {!isLoading ? <>{companyDept}</> : <Placeholder width={14} />}
            </Row>

            <Row alignRight>
              <Flags />
            </Row>
          </User>
        ) : (
          <ErrorAlert message={t("error.unable-to-load-user-data")} smallMargin />
        )}

        <Row noBorder>
          <Label onClick={onVersionClick}>v.{packageInfo.version}</Label>
          <Value className='copyright-container'>
            <span>&#xa9;&nbsp;</span>
            <span className='year'>{getCurrentYear()}</span>
          </Value>
        </Row>
      </InfoCard>
    </ContentContainer>
  );
}

export default Profile;

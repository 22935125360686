import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { isMobile } from "utils/utils";
import { getTranslations } from "./translations/tasks.translations";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

import { ContentContainer } from "styles/generalStyles";

import TasksHandler from "./components/tasks-handler/TasksHandler";

const Header = styled.div<{ isMobile: boolean }>`
  position: relative;
  padding-top: 1.5rem;
  padding-bottom: 1rem;

  .close-icon {
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 0.8rem;
    right: -0.4rem;
    cursor: ${({ isMobile }) => (!isMobile ? "pointer" : "none")};
  }
`;

const TitleContainer = styled.div`
  font-size: 2.4rem;
  display: flex;
  justify-content: center;
`;

const Title = styled.span`
  font-family: AuraAspect;
  position: relative;
`;

const TasksNumber = styled.span`
  position: absolute;
  right: -5.4rem;
  top: 0.5rem;
  font-family: GothamBold;
  font-size: 1.4rem;
  width: 5rem;
  color: ${({ theme }) => theme.primary_400};
`;

function UserTasks() {
  const navigate = useNavigate();
  const {
    i18n: { language },
  } = useTranslation();
  const { header } = getTranslations(language);
  const [tasksNumber, setTasksNumber] = useState(0);

  // --------------- On click handlers ---------------

  const onCloseIconClick = () => {
    const pathArray: string[] = JSON.parse(
      localStorage.getItem("tasksReturnPath") as string,
    );

    const path = pathArray.pop() as string;

    if (pathArray.length) {
      localStorage.setItem("tasksReturnPath", JSON.stringify(pathArray));
    } else {
      localStorage.removeItem("tasksReturnPath");
    }

    navigate(path, { replace: true });
  };

  return (
    <ContentContainer>
      <Header isMobile={isMobile}>
        <TitleContainer>
          <Title>
            {header.title}
            {!!tasksNumber && <TasksNumber>&#40;{tasksNumber}&#41;</TasksNumber>}
          </Title>
        </TitleContainer>
        <CloseIcon className='close-icon' onClick={onCloseIconClick} />
      </Header>

      <TasksHandler setTasksNumber={setTasksNumber} />
    </ContentContainer>
  );
}

export default UserTasks;
